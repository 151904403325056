import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Full Stack software engineer.`}</h4>
    <p><strong parentName="p">{`Take-away:`}</strong></p>
    <ul>
      <li parentName="ul">{`Product mindset.`}</li>
      <li parentName="ul">{`Technology focus.`}</li>
      <li parentName="ul">{`Lead engineer.`}</li>
      <li parentName="ul">{`Cloud engineering.`}</li>
      <li parentName="ul">{`Software Architecture.`}</li>
      <li parentName="ul">{`Distributed Systems enthusiast.`}</li>
      <li parentName="ul">{`Data Engineering.`}</li>
    </ul>
    <p><strong parentName="p">{`Programming languages:`}</strong></p>
    <ul>
      <li parentName="ul">{`Fluent in Javascript, Python, Golang`}</li>
    </ul>
    <br />
Developed many applications in multiple domains such as video processing, healthcare, accounting, cms, construction, 3D scanning, events management, web3 ecosystem, … with
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Node.js`}</inlineCode>{`, `}<inlineCode parentName="li">{`Python`}</inlineCode>{`, `}<inlineCode parentName="li">{`Golang`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`React.js`}</inlineCode>{`, `}<inlineCode parentName="li">{`React Native`}</inlineCode>{`, `}<inlineCode parentName="li">{`Cordova`}</inlineCode>{`, `}<inlineCode parentName="li">{`FastAPI`}</inlineCode>{`, `}<inlineCode parentName="li">{`NestJS`}</inlineCode>{`, `}<inlineCode parentName="li">{`NextJS`}</inlineCode>{`, `}<inlineCode parentName="li">{`GraphQL`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Airflow`}</inlineCode>{`, `}<inlineCode parentName="li">{`Dagster`}</inlineCode>{`, `}<inlineCode parentName="li">{`Step functions`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`MySQL`}</inlineCode>{`, `}<inlineCode parentName="li">{`MongoDB`}</inlineCode>{`, `}<inlineCode parentName="li">{`PostgreSQL`}</inlineCode>{`, `}<inlineCode parentName="li">{`Redis`}</inlineCode>{`, `}<inlineCode parentName="li">{`DynamoDB`}</inlineCode>{`, `}<inlineCode parentName="li">{`Elasticsearch`}</inlineCode>{`, `}<inlineCode parentName="li">{`Cosmos DB`}</inlineCode>{`, `}<inlineCode parentName="li">{`BigQuery`}</inlineCode>{`, `}<inlineCode parentName="li">{`RedShift`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`AWS services`}</inlineCode>{`, `}<inlineCode parentName="li">{`Google Cloud`}</inlineCode>{`, `}<inlineCode parentName="li">{`Azure`}</inlineCode>{`, `}<inlineCode parentName="li">{`Serverless`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Linux`}</inlineCode>{`, `}<inlineCode parentName="li">{`Docker`}</inlineCode>{`, `}<inlineCode parentName="li">{`Terraform`}</inlineCode>{`, `}<inlineCode parentName="li">{`Pulumi`}</inlineCode>{`, `}<inlineCode parentName="li">{`Kubernetes`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      